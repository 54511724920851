(() => {
  window.addEventListener('load', () => {
    var url = window.location.href;

    dataLayer.push({
      event: 'pageView',
      virtualUrl: url,
    });
  });
})();
